/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong",
    ul: "ul",
    li: "li",
    ol: "ol"
  }, _provideComponents(), props.components), {QuestionAccordion} = _components;
  if (!QuestionAccordion) _missingMdxReference("QuestionAccordion", true);
  return React.createElement(React.Fragment, null, React.createElement(QuestionAccordion, {
    question: "Why should I try hearing aids with hear.com?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "We offer the most advanced hearing aid technology, produced by the top hearing aid manufacturers. These hearing aids fit a wide range of hearing losses and lifestyles. There are a variety of discreet form factors available. Our 2,000 Partner Providers will provide you with a risk-free consultation and technology trial. Contact us today for a referral to a provider in your area."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/about-us/testimonials/",
    className: "c-md-a"
  }, "Read our customers’ reviews here."))), "\n", React.createElement(QuestionAccordion, {
    question: "How does hear.com work?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "One of our hearing aid experts will talk with you over the phone. The free consultation comes with no obligation. You will get unbiased answers to your questions. During the phone call, the expert will schedule you for an appointment with a Partner Provider in your area. The provider will test your hearing and, if you have a hearing loss, recommend the right technology for you. After the provider fits you with your hearing aids, you have 45 days to try them. If you are not satisfied, you get a full refund."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/about-us/how-it-works/",
    className: "c-md-a"
  }, "Read more about how hear.com works."))), "\n", React.createElement(QuestionAccordion, {
    question: "How long is the trial period?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "The trial period is 45 days. Within the first 30 days, you will work on getting adjusted to your hearing aids. Your coach will give you a copy of our Hearing Success Program", React.createElement("sup", null, "®"), " guidebook. The guidebook gives you training exercises and tips on learning to hear better with hearing aids. Think of these exercises as therapy for the auditory portion of your brain, much like a physical therapist might prescribe activities to strengthen your muscles. At the end of the 30-day training period, you and your coach will evaluate your progress. If you decide that your expectations have not been met, you have up to 15 additional days to return the devices for a full refund.")), "\n", React.createElement(QuestionAccordion, {
    question: "Are there any costs, beside the cost of hearing aids if I decide to purchase?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "You may need to pay up front for the ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing test"), " with some Partner Providers. However, if you purchase the hearing aids through hear.com, we will discount the price by the cost of the hearing test. You will not pay any fitting fees. If you decide to return the hearing aids, you get a full refund.")), "\n", React.createElement(QuestionAccordion, {
    question: "How much do hearing aids cost?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids range in price from $800 to $3,250 per ear. The cost of hearing aids depends on the technology present in the computer chip inside of the device. A computer chip that offers fancier noise reduction features and greater fine-tuning capabilities will cost more. The external appearance of the device- whether it goes behind your ear or fits entirely inside your ear canal- contributes little to hearing aid cost."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/prices/",
    className: "c-md-a"
  }, "Find out more about prices."))), "\n", React.createElement(QuestionAccordion, {
    question: "Will my health insurance cover hearing aids?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Health insurance coverage for hearing aids is mandated for children in 22 states. Only a few states require coverage for hearing aids for adults.\nWhen health insurance companies choose to cover hearing aids, they typically only pay up to a certain amount. This amount may only cover very basic level technology; you will need to cover the difference between the insurance company’s allowance and the cost of the technology you choose. Insurance companies normally cover the cost of hearing tests, provided that the tests are medically necessary.\nYou will need to consult with your particular health insurance plan for details about policies."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/health-insurance/",
    className: "c-md-a"
  }, "Find out more about insurances."))), "\n", React.createElement(QuestionAccordion, {
    question: "Can I get financing through hear.com?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Yes, we have a variety of financing options available, subject to credit approval."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Waived Interest if Paid in Full in 12 Months"), "\n*Minimum purchase $750"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "For approved Allegro Installment applicants:"), React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Rates as low as 5.99% APR for 36 Months"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "APR of 5.99% to 24.99% may apply."), "\n"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "*Examples:"), React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "A 36-month term of a $3,000 amount financed at 5.99% APR requires monthly payments of $91.25"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "A 36-month term of a $3,000 amount financed at 9.99% APR with a $199 down payment requires monthly payments of $96.79"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "A 60-month term of a $3,000 amount financed at 12.99% APR requires monthly payments of $68.24"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "A 36-month term of a $3,000 amount financed at 24.99% APR with a $199 down payment requires monthly payments of $119.26"), "\n"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Regular monthly payment amounts will not pay off loan to qualify for waived interest. To have interest waived, the full amount financed must be paid within 12 months. A down payment may be required. Program minimum amount financed is $750. Offer is subject to credit approval by Synchrony Bank."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Reduced APR and Fixed Monthly Payments Required Until Paid In Full**")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "On qualifying purchases made with your CareCredit credit card:"), React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "24 months offer with a 17.90% APR available on purchases of $1,000+"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "48 months offer with a 19.90% APR available on purchases of $1,000+"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "60 months offer with a 20.90% APR available on purchases of $2,500+"), "\n"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "**Interest will be charged on promo purchases from the purchase date. Fixed monthly payments are required until paid in full and will be calculated as follows: on 24-month promotions – 4.9876% of initial promo purchase amount; on 48-month promotions – 3.0377% of initial promo purchase amount or on 60-month promotion – 2.6997% of initial promo purchase amount. The fixed monthly payment will be rounded up to the next highest whole dollar and may be higher than the minimum payment that would be required if the purchase was a non-promo purchase. Any discount will reduce your total purchase amount and may result in you not satisfying the minimum qualifying purchase amount required. During the last month(s) of the promo period the required monthly payment may be reduced due to the prior months’ rounding. Regular account terms apply to non-promo purchases. For new accounts after 05/30/24: Purchase APR 32.99%. Penalty APR 39.99%; Minimum Interest Charge is $2. Existing cardholders: See your credit card agreement terms. Subject to credit approval.")), "\n", React.createElement(QuestionAccordion, {
    question: "Where do the differences between price ranges come from?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "The technology in the hearing aids determines the price. More expensive hearing aids offer more features designed to help patients achieve good speech understanding in challenging environments. You will also find more options such as ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth connectivity"), " in higher-end devices.")), "\n", React.createElement(QuestionAccordion, {
    question: "Where can I get an overview of all hearing aids and prices?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are many possible combinations of hearing aid styles, technology levels, and brands. The best first step is to talk with a hear.com expert, who will help narrow down your options based on your lifestyle and preferences. Your Partner Provider will further guide you after your hearing test. If you are concerned about cost, let your hear.com advisor and the hearing care provider know, so that they can work with you on finding a solution that fits your budget.")), "\n", React.createElement(QuestionAccordion, {
    question: "Can I insure my hearing aids?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids come with a loss and damage (that is, damaged beyond repair) warranty of 1 to 3 years, depending on the model. This warranty is provided by the manufacturer. The manufacturer will usually charge a deductible of a few hundred dollars to replace a lost or damaged hearing aid. Some manufacturers will allow you to extend this warranty for a longer period for a fee; your Partner Provider can assist you. Once you have exhausted the manufacturer’s loss and damage warranty, you can get insurance coverage as a rider on your homeowner’s insurance policy or contact a company that specializes in hearing aid insurance. Your Partner Provider can provide more information.")), "\n", React.createElement(QuestionAccordion, {
    question: "Do I need to visit an ENT to get hearing aids?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your hearing tests will be performed by an audiologist or a hearing instrument specialist. They have the training and expertise to do hearing tests. If they see any indications that you need referral for a medical evaluation, they will send you to an ENT doctor. In most cases, you can get hearing aids without visiting an ENT doctor.")), "\n", React.createElement(QuestionAccordion, {
    question: "How well do modern hearing aids perform?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Digital processing technology has greatly increased the capabilities of modern hearing aids. They perform computations thousands of times per second. These computations determine what kind of listening environment the user is in and adjust the programming accordingly. Some digital circuits are dedicated to reducing the interference of noise on speech understanding.")), "\n", React.createElement(QuestionAccordion, {
    question: "What is the difference between ITE and BTE?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "ITE stands for “in the ear” hearing aid. These hearing aids fit inside your ear canal and may fill part of the concha of your ear. The concha is the part of your outer ear that looks like a bowl. “BTE” hearing aids house the electronics in a case that fits behind your ear. A wire or a tube carries the sound into your ear from the case.\nBTE and ITE hearing aids can fit a wide range of hearing losses. However, sometimes people have ear anatomy that make one style a better choice. In addition, the most profound hearing losses require the power and superior feedback management provided by a BTE hearing aid. Find out more about ITE hearing aids.")), "\n", React.createElement(QuestionAccordion, {
    question: "Are hearing aids Bluetooth equipped? Can I connect my hearing aids to my new TV?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "It depends on the hearing aid model. Increasingly, manufacturers include Bluetooth connectivity in hearing aids. Some hearing aids can connect directly to smart phones. Other hearing aids need an intermediary device to work with a smart phone. There are special accessories that allow hearing aids to connect to televisions and other audio devices.")), "\n", React.createElement(QuestionAccordion, {
    question: "Are hearing aids waterproof?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids are not waterproof, but they are usually water-resistant. They can withstand everyday incidents such as running through the rain in a parking lot from the car into a store. Many hearing aids are built to meet IP67 or IP68 ratings, which certify that the hearing aids will resist moisture and dust based on the results of laboratory testing. However, even if a hearing aid has an IP68 rating, you should not deliberately drop your hearing aids in water. Treat your hearing aids as carefully as sensitive electronics in order to keep them working at their best.")), "\n", React.createElement(QuestionAccordion, {
    question: "I am wearing glasses, can wear hearing aids with them too?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Yes. Some patients with glasses prefer in-the-ear (ITE) styles. However, many patients successfully wear behind-the-ear (BTE) styles with glasses. Most BTE hearing aids are thin enough to fit on the ear alongside glasses. You just need to be careful when you take off your glasses. Make sure that you don’t accidentally take your hearing aids off along with your glasses, which can happen if you are in a hurry and yank your glasses off quickly.")), "\n", React.createElement(QuestionAccordion, {
    question: "Can I set up and adapt my hearing aid myself?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your hearing aids need to be programmed by a hearing care professional who has years of education, training, and experience. They have the knowledge about acoustics to shape the initial settings. In addition, they make sure that your hearing will be protected from further damage. From the settings that your professional provides, you can make some changes within limits. You can adjust volume and programs. If you use a smartphone app, you can fine-tune the bass and treble levels in the sound. The degree of flexibility that you have will be programmed by your hearing care professional. Most patients find that they prefer a limited number of options so that they don’t become overwhelmed.")), "\n", React.createElement(QuestionAccordion, {
    question: "Why should I take care of my hearing loss as soon as possible?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Over time, hearing loss isolates you from your friends and family. The negative impact of communication difficulties on your relationships, career, and quality of life will only increase over time. In addition, the auditory neurons in your brain start to change when you no longer hear normally. It becomes more difficult for the neurons to re-adjust to sound once you start to wear hearing aids. If you treat hearing loss early, you will have an easier time adjusting to hearing aids.")), "\n", React.createElement(QuestionAccordion, {
    question: "How do I know that I have hearing loss?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing loss tends to worsen gradually, so most likely your friends and family will notice first that you are having difficulties. People may tell you that you are not responding appropriately to their questions, or that you have the television on too loud. You may think at first that other people are “mumbling,” when in fact you are no longer hearing words as clearly as before. As your hearing loss progresses, you will likely notice that you strain to hear in noisy places like restaurants. Work meetings become more challenging. You may avoid using the telephone. The only way to know your hearing status for sure is to get a professional hearing exam. A hearing care professional will perform the exam with calibrated equipment, making sure that the results are accurate.")), "\n", React.createElement(QuestionAccordion, {
    question: "How do I find a hearing specialist near me?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "We have one of the largest networks of Partner Providers in the world, with 2,000 hearing care professionals ready to help you. Register on our website today and a consultant will reach out to you to schedule an appointment in your area.")), "\n", React.createElement(QuestionAccordion, {
    question: "My hearing aids don't work, what should I do?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Are they turned on properly or is the battery dead?")), React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Check to make sure your hearing aids are on by touching the top of the hearing aid and listening for a scratchy sound."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "If your hearing aid uses batteries, try replacing them. Make sure to pull the sticker off the battery and insert it properly. Fun fact: hearing aid batteries are powered by oxidizing zinc with oxygen from the air, so let it “breath” for 2 minutes to improve performance. Then, make sure your battery door is completely closed."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "If you have rechargeable devices, check the charge on the charging case or the smartphone app. When you charge, make sure your hearing aids are securely in the dock and the light is on."), "\n"), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Is the volume at the appropriate level?")), React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Try resetting your devices. For rechargeable devices, place them in the charger for a minute. For battery-operated devices, open the battery door and then close it."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Also check and adjust your hearing aids' volume using the smartphone app. If you need help, please check out this article: ", React.createElement(_components.a, {
    href: "/resources/hearing-aids/how-to-connect-hearing-aids-to-smartphone/",
    className: "c-md-a"
  }, "\"How to Connect Your Hearing Aids to Your Smartphone.\"")), "\n"), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Are they dirty or clogged?")), React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Try cleaning your devices with the cleaning tools that came with your devices. Brush off the microphone and remove ear wax or other debris."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Then replace your wax filters or guards and the domes, even if they were recently changed."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Also consider a professional cleaning, which you should do once or twice a year."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "For more information, check out this article to learn \"How to Keep Your Hearing Aids in Tip-Top Shape.\""), "\n"), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "If these tips don't solve your problem, please call us at ", React.createElement(_components.a, {
    href: "tel:7865202456",
    className: "c-md-a"
  }, "(786)520-2456"), " so we can find a solution for you. To help troubleshoot, we'll want to know:")), React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "How long have you had these hearing aids? How often are you wearing them?"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Are both hearing aids affected or just one? If so, which one?"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Did something happen recently that could have caused this? For example, did they get wet, get stepped on, or get chewed up by the dog?"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Does the device fade in or out? Or does it sound like it's losing its connection? Or only work occasionally? Sometimes this can happen when you get sweaty or are in extremely humid environments."), "\n")), "\n", React.createElement(QuestionAccordion, {
    question: "Why does my own voice sound strange?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you've ever heard yourself in a telephone message or on a home movie, you probably felt like you sounded much different in the recording than you do in real life. One of the reasons your voice sounds richer when you speak (vs. hearing it through a device) is bone conduction. Your vocal cords create vibrations in your skull that make your voice sound lower and richer than it actually is. To learn more about this topic, check out this article, ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/getting-used-to-the-sound-of-your-own-voice-with-hearing-aids/",
    className: "c-md-a"
  }, "\"Getting Used to the Sound of Your Own Voice With Hearing Aids.\"")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you just started wearing new hearing aids or you wear them infrequently, the best thing you can do is to wear them consistently every day for at least one month. To find out why, we recommend reading the article, ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/why-slow-and-steady-wins-the-race-with-new-hearing-aids/",
    className: "c-md-a"
  }, "\"Why Slow and Steady Wins the Race With New Hearing Aids.\""), " Your brain needs to adjust to hearing with them. Within a week, it'll determine this as the new normal and you won't notice it anymore. If you'd like to speed up the process, one proven technique is to read aloud."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you're wearing your hearing aids consistently every day and you continue to have trouble, please give us a call at ", React.createElement(_components.a, {
    href: "tel:7865202456",
    className: "c-md-a"
  }, "(786)520-2456"), " so we can find a solution for you.")), "\n", React.createElement(QuestionAccordion, {
    question: "My hearing aids don't work, what should I do?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Have you been wearing your hearing aids less than 15 days?")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Surprisingly, people with hearing loss are often sensitive to sounds and it's very normal for this to continue when you first start wearing new hearing aids. Your brain has been missing certain sounds for a while and it must relearn how to hear again. This adjustment period usually takes about 30 days. Experts recommend to start slowly and gradually build up to wearing your devices all day. For example, in the first couple days, it's recommended to wear your hearing aids in quieter places, like your home, for 2-4 hours a day. By the end of the first week, aim to be at about 4-6 hours a day. The more times you experience sounds with your new hearing aids, the less alerting it will be."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Are your hearing aids too loud all of the time?")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you've been wearing your hearing aids less than 15 days, please see above."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "If not, consider adjusting the volume to a level that is more comfortable for you. The process is pretty straightforward with your hearing aids' smartphone app, but if you need assistance we're happy to help you. If you haven't already downloaded your hearing aids' smartphone app, here's a helpful article, ", React.createElement(_components.a, {
    href: "/resources/hearing-aids/how-to-connect-hearing-aids-to-smartphone/",
    className: "c-md-a"
  }, "\"How to Connect Your Hearing Aids to Your Smartphone.\"")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you've tried adjusting the volume and the loudness is preventing you from wearing your hearing aids, we'll want to connect you with one of our experts to help diagnose the issue. Please call us at ", React.createElement(_components.a, {
    href: "tel:7865202456",
    className: "c-md-a"
  }, "(786)520-2456"), " so we can find a solution for you."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Are your hearing aids too loud only in certain situations or are certain sounds too loud?")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you've been wearing your hearing aids less than 15 days, please see above."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you've had them longer than 15 days, it's important to know that most volume issues aren't due to the actual devices, but how we personalize your hearing aid programs based on your feedback. Your audiogram provides the amplification you require at different frequencies, but how you experience certain sounds is very personal."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Therefore, we need to know what you like or don't like in order to “fine-tune” your hearing aids. It's a good idea to keep some notes in a little notebook or on your phone. Since your hearing memory is short-term, it's important to be as descriptive as possible on the sound, the setting you were using, and the environment you were in. The more information you can provide our hearing care professional, the better they can fine-tune your hearing aids to your liking."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Then, please call us at ", React.createElement(_components.a, {
    href: "tel:7865202456",
    className: "c-md-a"
  }, "(786)520-2456"), ", so we can connect you with one of our experts and find a solution for you.")), "\n", React.createElement(QuestionAccordion, {
    question: "What if the sound is tinny, high-pitched, or harsh?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "One of the most frequent comments we hear in the beginning is that sounds are tinny, which is due to high-pitch amplification. The higher pitches are typically the sounds you've been missing the longest and are also the sounds that are the most alerting. But these are also the sounds responsible for about two-thirds of speech understanding. So while these high-pitched sounds might be a bit abrasive now, they will ultimately give you the speech clarity and understanding you need from your hearing aids."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Imagine you're sitting in a dark room and someone suddenly flipped on the light. Your initial reaction is to turn it off because it seems much too bright. However, we all know that our eyes quickly adjust to the light. Your ears have been missing high pitch sounds for a long time, oftentimes at least 5-7 years. Just like the bright light, they're suddenly being exposed to sounds again, which takes some adjusting. But with consistent wear, your brain will learn to hear these sounds again and after the first month, you won't even pay attention to the tinny sound."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "After the first 30 days, if you're continuing to experience tinny, high-pitched, or harsh sounds, it's important to know that adjustments can be made based on your feedback. Your audiogram provides the amplification you require at different frequencies, but how you experience certain sounds is very personal. By knowing what you like or don't like, your hearing aids can be “fine-tuned.” It's a good idea to keep some notes in a little notebook or on your phone. Since your hearing memory is short-term, it's important to be as descriptive as possible on the sound, the setting you were using, and the environment you were in. The more information you can provide our hearing care professional, the better they can fine-tune your hearing aids to your liking. Then please call us at ", React.createElement(_components.a, {
    href: "tel:7865202456",
    className: "c-md-a"
  }, "(786)520-2456"), ", so we can connect you with one of our experts to find a solution for you.")), "\n", React.createElement(QuestionAccordion, {
    question: "Why are my ears itching?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you start wearing new hearing aids, it's normal to have a tickling or itching sensation. Your ear canals are very sensitive, so your devices can produce a feeling similar to a feather touching your skin. Like many new sensations, the tickling or itching feeling is only temporary and your body will get used to it as you wear them consistently. It's important to wear them every day, but stick to the recommended wearing schedule to avoid overdoing it. Start by wearing them for a couple hours a day and gradually increase your wearing time until you're able to wear them all day by the end of your first month."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "If your ears are very dry or the itching continues, try to use a moisturizer, lubricant or anti-itch cream at the opening of your ear canal at night before bed. Make sure to wipe out the ear canal the next morning before inserting your hearing aids. For additional information or tips, check out the article, ", React.createElement(_components.a, {
    href: "/resources/hearing-aids/itchy-ears-and-new-hearing-aids/",
    className: "c-md-a"
  }, "\"Itchy Ears and New Hearing Aids.\"")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you continue to have problems with itching or your ears are draining, swollen or have an odor, please call us at 888-780-3450 so we can find a solution for you. We can connect you with one of our experts to address the issue which may be unrelated to your hearing aids like an ear infection, allergies, or swimmers ear.")), "\n", React.createElement(QuestionAccordion, {
    question: "Why do my ears feel clogged or like my head is in a tunnel, barrel, or underwater?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Does this occur when you are talking?")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "One of the reasons it sounds like your ears are clogged or it feels like your head is in a barrel when you speak is due to bone conduction. Your vocal cords create vibrations in your skull that make your voice sound lower and richer than it actually is. Because the device is physically in your ear, sometimes it creates the effect of being “bottled up.” In the majority of cases, it's just a matter of time before your brain will adjust and you'll forget about it. If you just started wearing new hearing aids or you wear them infrequently, the best thing you can do is to wear them consistently every day. Within a week, your brain will determine this as the new normal and you'll no longer notice it. To learn more about this topic, check out this article, ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/getting-used-to-the-sound-of-your-own-voice-with-hearing-aids/",
    className: "c-md-a"
  }, "\"Getting Used to the Sound of Your Own Voice With Hearing Aids.\"")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "For a small number of people, this effect happens because the hearing aids are too snug and act like a plug. Your degree of hearing loss may require some closing of the ear canals to achieve optimal hearing."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "So if you're wearing your hearing aids consistently every day and you continue to have trouble, please give us a call at ", React.createElement(_components.a, {
    href: "tel:7865202456",
    className: "c-md-a"
  }, "(786)520-2456"), " so we can find a solution for you."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Does this occur when you are talking?")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Prior to getting hearing aids, you typically don't have something in your ears for hours at a time, every day. This is a very normal experience and takes some time to adjust to the sensation of having hearing aids in your ears. To learn more about the adjustment process, please read this article, \"Why Slow and Steady Wins the Race With New Hearing Aids.\" Your level of hearing loss can impact how closed off your ear canals will need to be by the hearing aids to achieve the amount of amplification required. Because you have something physically blocking the opening of the ear canal, it's common to feel like your ears are “bottled up.” You may notice that you hear yourself breathing more loudly or that your footsteps feel heavier when you walk across the floor."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Simply wearing your devices daily will typically solve this issue. However, if this sensation persists, sometimes it can be alleviated by changing the physical fit of the device or adjusting your settings. Please call us at ", React.createElement(_components.a, {
    href: "tel:7865202456",
    className: "c-md-a"
  }, "(786)520-2456"), " so we can connect you with one of our experts and find a solution for you.")), "\n", React.createElement(QuestionAccordion, {
    question: "What can I do if my hearing aids fall or slip out?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "For the best performance and comfort, your hearing aids must be properly inserted in and/or around your ear. Putting on your hearing aid correctly every time takes practice. Oftentimes, the reason devices fall or slip out of the ear can be easily fixed with some simple tips."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "For behind-the-ear hearing aids:")), React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Make sure you're inserting your hearing aids into the correct ear. The right hearing aid will have red markings. The left hearing aid will have blue markings."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Next, place the hearing aid behind your ear."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Then, hold the end of the end of the tube between your thumb and index finger, and point the earpiece toward your ear canal opening."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Finally, gently push the earpiece into your ear canal until the thin tube sits close against the side of your head."), "\n"), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "For in-the-ear hearing aids:")), React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Make sure you're inserting your hearing aids into the correct ear. The right hearing aid will have red markings. The left hearing aid will have blue markings."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Next, hold the hearing aid with the vent or removal cord facing down and place the tip of the hearing aid in your ear canal."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Gently pull your ear outwards and push the hearing aid into the ear canal, ensuring that it is positioned correctly. In the beginning, use a mirror to check."), "\n"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "If your devices continue to fall or slip out, please give us a call at ", React.createElement(_components.a, {
    href: "tel:7865202456",
    className: "c-md-a"
  }, "(786)520-2456"), " so we can connect you with one of our experts and find a solution for you.")), "\n", React.createElement(QuestionAccordion, {
    question: "My hearing aids don't seem to make a big difference for me, what can I do?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "First, make sure your hearing aids are properly turned on, the battery is charged, the volume is at the appropriate level, and properly cleaned."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "If everything checks out, it's common to feel like you're hearing better but that you expected to hear more. Perhaps you're not hearing significantly better in places with background noise or in places that you were struggling in before your hearing aids."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "One of the amazing features of today's technology is that your devices can be adjusted to closely meet your expectations. Our experts can easily adjust your settings to give you the benefit you need. Oftentimes, new devices are set below your optimal settings because many people have a difficult time adjusting to the heightened sounds with their hearing aids. You may be someone who adapts to things more easily and you may be able to increase your settings faster. But it's critical to wear your hearing aids consistently so your brain will start to integrate the sound better and the benefit of your devices will be more apparent. The longer you wear your devices the more likely you are going to want to increase the sound."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Utilizing the remote app through your smartphone to adjust your settings or volume will also help you have more control of how you hear. Check out this article if you need to learn ", React.createElement(_components.a, {
    href: "/resources/hearing-aids/how-to-connect-hearing-aids-to-smartphone/",
    className: "c-md-a"
  }, "\"How to Connect Your Hearing Aids to Your Smartphone.\"")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Routine cleaning is also critical for consistent sound. Make sure you know ", React.createElement(_components.a, {
    href: "/resources/hearing-aids/how-to-keep-your-hearing-aids-in-tip-top-shape-for-years-to-come/",
    className: "c-md-a"
  }, "\"How to Keep Your Hearing Aids in Tip-Top Shape\""), " so you don't have any lapse in performance."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "If places with lots of background noise are a problem, you're not alone (and even people with great hearing struggle in these situations). Click here to learn ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/how-to-hear-your-best-in-challenging-situations/",
    className: "c-md-a"
  }, "\"How to Hear Your Best in Challenging Situations\""), ". Consistently wearing your hearing aids is key to optimal hearing in these situations. If you've been wearing your hearing aids less than 30 days or only wear them occasionally, then your brain will have a difficult time integrating the sound in a way that allows you to hear well with lots of background noise. But advanced settings and additional adjustments can be made to improve your experience in noisy environments, like restaurants or larger social gatherings. If you'd like, please call us at 888-780-3450 so we can connect you with one of our experts and find a solution for you.")), "\n", React.createElement(QuestionAccordion, {
    question: "How can I return my devices after my in-person appointment?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "As long as you're within the 45-day trial, the process is very simple."), React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Please call 888-780-3450 to let us know you'd like to return your devices. Then we can determine the best way for you to send them back before the end of the 45-day trial."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "After we confirm the devices have been returned, we'll process it within 5 business days.\n- If you paid with a credit card, your refund will show on your card 2-3 business days after we process it.\n-If you made payments through our financing partner, you'll receive a check from them within 4-5 weeks."), "\n")), "\n", React.createElement(QuestionAccordion, {
    question: "How can I return my devices after my remote video appointment?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "As long as you're within the 45-day trial, the process is very simple"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you want to return your hearing aids after your remote video appointment, please click this link ", React.createElement(_components.a, {
    href: "/faqs/returns",
    className: "c-md-a"
  }, "https://www.hear.com/faqs/returns"), " to start the return process.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
